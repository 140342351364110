/* third lib */
import React from "react";

/* material-ui */
import SvgIcon from "@material-ui/core/SvgIcon";

function Reject(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1024 1024" width="200" height="200">
      <path
        d="M509.579636 46.545455C253.905455 46.545455 46.545455 253.812364 46.545455 509.579636s207.266909 462.941091 463.034181 462.941091a462.987636 462.987636 0 0 0 463.034182-463.034182C972.613818 253.672727 765.253818 46.545455 509.579636 46.545455z m0 858.577454a395.636364 395.636364 0 0 1-395.636363-395.636364c0-218.577455 177.058909-395.636364 395.636363-395.636363s395.636364 177.105455 395.636364 395.636363a395.636364 395.636364 0 0 1-395.636364 395.636364z"
        p-id="8342"
      ></path>
      <path
        d="M669.602909 256l71.400727 59.671273L382.370909 744.727273 310.923636 685.056z"
        p-id="8343"
      ></path>
    </SvgIcon>
  );
}

export default Reject;
