/* third lib */
import React from "react";

/* material-ui */
import SvgIcon from "@material-ui/core/SvgIcon";

function Success(props) {
  return (
    <SvgIcon {...props} width="165" height="165" viewBox="0 0 165 165">
      <path id="路径_60" data-name="路径 60" d="M0,0H165V165H0Z" fill="none" />
      <path
        id="减去_3"
        data-name="减去 3"
        d="M-7859.281-1319.455H-7954.8a14.324,14.324,0,0,1-10.026-4.041,13.452,13.452,0,0,1-4.17-9.717v-96.312a13.455,13.455,0,0,1,4.17-9.718,14.323,14.323,0,0,1,10.026-4.042l28.269.08a21.584,21.584,0,0,1,7.364-9.89,20.142,20.142,0,0,1,12.046-3.905,20.414,20.414,0,0,1,12.117,3.905,21.467,21.467,0,0,1,7.433,9.89l28.3-.08a14.318,14.318,0,0,1,10.023,4.042,13.453,13.453,0,0,1,4.17,9.718v96.312a13.451,13.451,0,0,1-4.17,9.717A14.319,14.319,0,0,1-7859.281-1319.455Zm-83.534-63.849h0l-8.653,9.194,28.875,28.873,61.875-61.875-8.652-9.358-53.223,52.683-20.222-19.518Zm35.667-59.885a7,7,0,0,0-7.1,6.878,6.728,6.728,0,0,0,2.084,4.859,7.162,7.162,0,0,0,5.013,2.021,7.162,7.162,0,0,0,5.013-2.021,6.728,6.728,0,0,0,2.085-4.859A7,7,0,0,0-7907.148-1443.188Z"
        transform="translate(7989.625 1468.985)"
        fill="#8fa0f5"
      />
      <g id="done-black-48dp" transform="translate(20.625 25.781)">
        <path
          id="路径_180"
          data-name="路径 180"
          d="M0,0H123.75V123.75H0Z"
          fill="none"
        />
      </g>
    </SvgIcon>
  );
}

export default Success;
