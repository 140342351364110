/* third lib*/
import React, { useEffect, useState } from "react";
import cn from "classnames";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ScrollBar from "react-perfect-scrollbar";

/* local components & methods */
import styles from "./styles.module.scss";

const DesignPanel = ({ open, handleClose }) => {
  const [openState, setOpenState] = useState(false);

  useEffect(() => {
    if (!open) {
      setOpenState(false);
      return;
    }
    setTimeout(() => {
      setOpenState(true);
    }, 0);
  }, [open]);

  if (!open) {
    return <></>;
  }

  return (
    <div className={styles.mask}>
      <ClickAwayListener onClickAway={handleClose}>
        <div
          id="designPanel"
          className={cn(styles.designerPanel, {
            [styles["open"]]: openState,
          })}
        >
          <ScrollBar>
            <div className={styles.innerContent}>323123213</div>
          </ScrollBar>
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default DesignPanel;
