/* third lib */
import React from "react";

/* material-ui */
import SvgIcon from "@material-ui/core/SvgIcon";

function TableView(props) {
  return (
    <SvgIcon {...props} width="200" height="200" viewBox="0 0 1024 1024">
      <path
        d="M717.499 675.1c32.553-44.735 50.143-98.442 50.143-154.863 0-70.399-27.36-136.587-77.042-186.373-49.698-49.802-115.779-77.229-186.072-77.23-0.004 0 0.004 0 0 0-70.288 0-136.378 27.43-186.074 77.23-49.682 49.785-77.042 115.974-77.043 186.372 0 70.399 27.361 136.588 77.043 186.374 49.699 49.802 115.781 77.229 186.074 77.229 64.156 0 124.798-22.86 172.685-64.715l219.183 219.692c6.949 6.965 15.948 10.515 24.584 10.515 7.456 0 14.642-2.646 20.005-8.025 11.575-11.609 10.451-31.726-2.497-44.705L717.499 675.1z m-212.972 48.74c-54.242 0-105.242-21.171-143.603-59.612-79.232-79.396-79.232-208.584 0-287.981 38.361-38.441 89.361-59.612 143.604-59.612 54.242 0 105.241 21.171 143.601 59.612 79.231 79.397 79.231 208.586 0 287.981-38.36 38.441-89.359 59.612-143.602 59.612z"
        p-id="46711"
      ></path>
      <path
        d="M894 60H118c-33 0-60 27-60 60v776c0 33 27 60 60 60h559c16.5 0 30-13.5 30-30s-13.5-30-30-30H118V120h776v557c0 16.5 13.5 30 30 30s30-13.5 30-30V120c0-33-27-60-60-60z"
        p-id="46712"
      ></path>
    </SvgIcon>
  );
}

export default TableView;
