/* third lib */
import React from "react";
import { Outlet } from "react-router-dom";
import ScrollBar from "react-perfect-scrollbar";
import cn from "classnames";

/* local components & methods */
import styles from "./styles.module.scss";
import withAuthentication from "src/hoc/withAuthentication";

const DashboardLayout = ({ singlePage }) => {
  return (
    <div className={styles.dashboardLayout}>
      <div className={styles.wrapper}>
        {singlePage && (
          <div className={cn(styles.content, styles.singlePage)}>
            <ScrollBar>
              <Outlet />
            </ScrollBar>
          </div>
        )}
        {!singlePage && (
          <div className={styles.content}>
            <Outlet />
          </div>
        )}
      </div>
    </div>
  );
};

export default withAuthentication(DashboardLayout);
