/* third lib */
import React from "react";

/* material-ui */
import DatePicker from "@basics/DatePicker";

/* local components & methods */
import styles from "./styles.module.scss";

const TimePeriod = ({ value, id, onChange }) => {
  let from = "";
  let to = "";
  let timeValue = value ? value : [];
  let fromIndex, toIndex;

  timeValue.forEach((time, index) => {
    if (time[1] === ">") {
      from = time[0];
      fromIndex = index;
    }
    if (time[1] === "<") {
      to = time[0];
      toIndex = index;
    }
  });

  return (
    <div className={styles.timePeriod}>
      <div className={styles.detail}>
        <DatePicker
          value={from}
          onChange={(value) => {
            if (timeValue[fromIndex]) {
              timeValue[fromIndex][0] = value;
            } else {
              timeValue.push([value, ">"]);
            }

            onChange([...timeValue]);
          }}
        />
        <div className={styles.sep}>-</div>
        <DatePicker
          value={to}
          onChange={(value) => {
            if (timeValue[toIndex]) {
              timeValue[toIndex][0] = value;
            } else {
              timeValue.push([value, "<"]);
            }
            onChange([...timeValue]);
          }}
        />
      </div>
    </div>
  );
};

export default TimePeriod;
