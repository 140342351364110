/* third lib*/
import React, { useState, useMemo, useEffect, useCallback } from "react";
import { FormattedMessage as Intl } from "react-intl";
import { useNavigate } from "react-router-dom";
import cn from "classnames";

/* material-ui */
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { Tooltip } from "@material-ui/core";

/* local components & methods */
import OnboardDataDisplay from "@comp/OnboardDataDisplay";
import styles from "./styles.module.scss";
import Model from "@basics/Modal";
import { getTableSchema } from "@lib/api";
import TableTagDisplay from "@comp/TableTag";
import Loading from "@assets/icons/Loading";
import Text from "@basics/Text";
import { sendNotify } from "src/utils/systerm-error";
import DesignPanel from "../DesignPanel";
import bigQuery from "src/assets/bigquery.png";
import hive from "src/assets/hive.png";
import Database from "src/assets/icons/Database";
import TableView from "src/assets/icons/TableView";
import { covertToCurrentTime } from "src/utils/timeFormat";
import { useGlobalContext } from "src/context";
// import Tooltip from "@basics/Tooltip";

const CardItem = ({ cardData, resourceDetail }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(true);
  const [cartOpen, setCartOpen] = useState(false);

  const { timeContext } = useGlobalContext();
  const covertTime = useCallback(
    (date) => {
      return covertToCurrentTime(date, timeContext.timeFormat);
    },
    [timeContext]
  );

  const connection_type = useMemo(() => {
    return cardData.connection_type ? cardData.connection_type : "GCP";
  }, [cardData]);

  const tableTags = useMemo(() => {
    if (cardData?.in_use?.tags) {
      return Object.keys(cardData?.in_use?.tags).map((tag) => {
        let details = cardData?.in_use?.tags[tag];
        let attrList = Object.keys(details.data).map((key) => {
          return { label: details.labels[key], value: details.data[key] };
        });

        return { tagName: tag, attrList: attrList };
      });
    }
    return [];
  }, [cardData]);

  const policyTags = useMemo(() => {
    if (cardData?.in_use?.policyTags) {
      return Object.keys(cardData?.in_use?.policyTags).map((tag) => {
        return { tagName: tag, detail: cardData?.in_use?.policyTags[tag] };
      });
    }
    return [];
  }, [cardData]);

  const onAddCartClick = useCallback(() => {
    navigate(
      `/app/getDataAccess?type=${connection_type}&id=${cardData.tableReference.projectId}&dataset=${cardData.tableReference.datasetId}&name=${cardData.tableReference.tableId}`
    );
  }, [cardData, navigate, connection_type]);

  const handleClose = useCallback(() => {
    setCartOpen(false);
  }, []);

  useEffect(() => {
    if (open) {
      setLoading(true);
      getTableSchema({
        resourceType: connection_type,
        projectId: cardData.tableReference.projectId,
        datasetName: cardData.tableReference.datasetId,
        tableName: cardData.tableReference.tableId,
      })
        .then((res) => {
          if (res.data) {
            setTableData(res.data);
            setLoading(false);
          }
        })
        .catch((e) => {
          sendNotify({ msg: e.message, status: 3, show: true });
        });
    }
  }, [resourceDetail, cardData, open, connection_type]);

  return (
    <>
      <Card
        className={cn(styles.cardItem, {
          [styles[connection_type]]: connection_type,
        })}
      >
        <CardContent className={styles.cartContent}>
          <div className={styles.cardHeader}>
            <div className={styles.tableDes}>
              <div className={styles.headerTitle}>
                <Text type="title"> {cardData.tableReference.tableId}</Text>
              </div>

              <div className={styles.hearderDes}>
                <Text type="small"> {cardData.description}</Text>
              </div>
            </div>
            <div className={styles.projectIcon}>
              {connection_type === "GCP" && (
                <img
                  className={styles.tableImg}
                  src={bigQuery}
                  alt={connection_type}
                />
              )}
              {connection_type === "Hive" && (
                <img
                  className={styles.tableImg}
                  src={hive}
                  alt={connection_type}
                />
              )}
            </div>
          </div>
          <div className={styles.mainDetail}>
            <div className={styles.tableDetail}>
              <div className={styles.cardLine}>
                <div className={styles.attrLabel}>
                  <Intl id="projectName" />
                </div>
                <div className={styles.value}>
                  {cardData.tableReference.projectId}
                </div>
              </div>
              <div className={styles.cardLine}>
                <div className={styles.attrLabel}>
                  <Intl id="dataSet" />
                </div>
                <div className={styles.value}>
                  {cardData.tableReference.datasetId}
                </div>
              </div>
              <div className={styles.cardLine}>
                <div className={styles.attrLabel}>
                  <Intl id="location" />
                </div>
                <div className={styles.value}>{cardData.location}</div>
              </div>
              {/* <div className={cn(styles.cardLine, styles.dataOwner)}>
                <div className={styles.attrLabel}>
                  <AccountCircleIcon />:
                </div>
                <div className={styles.value}>{cardData.data_owner}</div>
              </div> */}
            </div>
            <div className={styles.typeIcon}>
              {cardData.type === "TABLE" && <Database />}
              {cardData.type === "VIEW" && <TableView />}
            </div>
          </div>
          <div className={styles.mainDetail}>
            <div className={cn(styles.tableDetail, styles.tagsDetail)}>
              {tableTags && tableTags.length > 0 && (
                <div className={styles.tagBox}>
                  <div className={styles.tagTitle}>
                    <Intl id="tableTags" />
                  </div>
                  <div className={styles.tagContainer}>
                    {tableTags.map((tagItem, index) => {
                      return (
                        <Tooltip
                          key={index}
                          arrow
                          title={
                            <React.Fragment>
                              {tagItem.attrList.map((attr, index) => {
                                return (
                                  <div key={index} className={styles.tagAttr}>
                                    <div className={styles.attrLabel}>
                                      {attr.label}:
                                    </div>
                                    <div className={styles.attrValue}>
                                      {attr.value}
                                    </div>
                                  </div>
                                );
                              })}
                            </React.Fragment>
                          }
                          placement="bottom"
                        >
                          <div className={styles.tagItem}>
                            {tagItem.tagName}
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              )}
              {policyTags && policyTags.length > 0 && (
                <div className={styles.tagBox}>
                  <div className={styles.tagTitle}>
                    <Intl id="policyTag" />
                  </div>
                  <div className={styles.tagContainer}>
                    {policyTags.map((policyTag, index) => {
                      return (
                        <Tooltip
                          key={index}
                          title={policyTag.detail.ad_groups}
                          placement="bottom"
                          arrow
                        >
                          <div className={styles.tagItem}>
                            {policyTag.tagName}
                          </div>
                        </Tooltip>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardContent>
        <div className={styles.bottomBox}>
          <div className={styles.accessTime}>
            <AccessTimeIcon className={styles.bottomIcon} />
            <div className={styles.value}>
              {covertTime(Number(cardData.creationTime))}
            </div>
          </div>
          <CardActions className={styles.addToCard}>
            <VisibilityIcon
              size="small"
              onClick={() => {
                setOpen(true);
              }}
            />
            <ShoppingCartIcon onClick={onAddCartClick} size="small" />
          </CardActions>
        </div>
      </Card>

      {cartOpen && <DesignPanel open={cartOpen} handleClose={handleClose} />}

      {open && (
        <Model
          open={open}
          handleClose={() => {
            setOpen(false);
          }}
        >
          <div className={styles.modalContent}>
            {loading && (
              <div className={styles.loading}>
                <Loading />
              </div>
            )}
            {!loading && tableData && (
              <>
                <div className={styles.content}>
                  <div className={styles.contentTitle}>
                    <Text type="title">
                      <Intl id="tableTags" />
                    </Text>
                  </div>
                  <div>
                    {tableData.tags.map((tag, index) => {
                      return <TableTagDisplay key={index} tagData={tag} />;
                    })}
                  </div>
                </div>
                <div className={styles.content}>
                  <div className={styles.contentTitle}>
                    <Text type="title">
                      <Intl id="tableSchema" />
                    </Text>
                  </div>
                  <OnboardDataDisplay tableList={tableData.schema.fields} />
                </div>
              </>
            )}
          </div>
        </Model>
      )}
    </>
  );
};

export default CardItem;
