export default [
  {
    label: "Asia/Hong_Kong",
    value: "Asia/Hong_Kong",
    suffix: "(HKT)",
  },
  {
    label: "Europe/London",
    value: "Europe/London",
    suffix: "(GMT)",
  },
  {
    label: "Asia/Shanghai",
    value: "Asia/Shanghai",
    suffix: "(CST)",
  },
  {
    label: "Asia/Kolkata",
    value: "Asia/Kolkata",
    suffix: "(IST)",
  },
  {
    label: "Asia/Tokyo",
    value: "Asia/Tokyo",
    suffix: "(JST)",
  },
  {
    label: "America/Regina",
    value: "America/Regina",
    suffix: "(CST)",
  },
];
