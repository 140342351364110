/* third lib */
import React from "react";

/* material-ui */
import SvgIcon from "@material-ui/core/SvgIcon";

function Database(props) {
  return (
    <SvgIcon {...props} width="200" height="200" viewBox="0 0 1024 1024">
      <path
        d="M510.664 0C271.44 0 84 84.352 84 192v640c0 107.648 187.44 192 426.664 192 239.232 0 426.672-84.352 426.672-192V192c0-107.648-187.44-192-426.672-192z m384 832c0 70.616-157.696 149.336-384 149.336s-384-78.72-384-149.336V703.232c68.4 64.08 212.696 107.44 384 107.44s315.608-43.36 384-107.44V832z m0-213.336c0 70.616-157.696 149.336-384 149.336s-384-78.72-384-149.336V489.896c68.4 64.088 212.696 107.44 384 107.44s315.608-43.36 384-107.44v128.768z m0-213.328c0 70.608-157.696 149.336-384 149.336s-384-78.72-384-149.336V276.56C195.064 340.64 339.36 384 510.664 384s315.608-43.352 384-107.44v128.776z m-384-64c-226.304 0-384-78.72-384-149.336 0-70.616 157.696-149.336 384-149.336s384 78.72 384 149.336c0 70.616-157.696 149.336-384 149.336z"
        p-id="3982"
      ></path>
    </SvgIcon>
  );
}

export default Database;
