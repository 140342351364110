/* third lib*/
import React, { useCallback, useMemo } from "react";
import cn from "classnames";

/* material-ui */
import Delete from "@material-ui/icons/Delete";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import VisibilityIcon from "@material-ui/icons/Visibility";

/* local components & methods */
import styles from "./styles.module.scss";
import Text from "@basics/Text";
import bigQuery from "src/assets/bigquery.png";
import hive from "src/assets/hive.png";
import { covertToCurrentTime } from "src/utils/timeFormat";
import Database from "src/assets/icons/Database";
import TableView from "src/assets/icons/TableView";
import { useGlobalContext } from "src/context";

const CartItem = ({ row, seq, showBackItem, removeCartItem }) => {
  const { timeContext } = useGlobalContext();
  const covertTime = useCallback(
    (date) => {
      return covertToCurrentTime(date, timeContext.timeFormat);
    },
    [timeContext]
  );

  const connection_type = useMemo(() => {
    return row.connection_type ? row.connection_type : "GCP";
  }, [row]);

  return (
    <div className={cn(styles.cartItem, styles[connection_type])}>
      <div className={styles.itemHeader}>
        <div
          className={styles.itemName}
          onClick={() => {
            showBackItem(row);
          }}
        >
          <div className={styles.imgBox}>
            {connection_type === "GCP" && (
              <img src={bigQuery} alt="Big Query" className={styles.gcpIcon} />
            )}
            {connection_type === "Hive" && (
              <img src={hive} alt="Hive" className={styles.hiveIcon} />
            )}
          </div>
          {row.tableReference.tableId}
        </div>
      </div>
      <div className={styles.itemDetail}>
        <div className={styles.detailAttr}>
          <div className={styles.detailLine}>
            <div className={styles.label}>
              <Text>project ID:</Text>
            </div>
            <div className={styles.value}>
              <Text>{row.tableReference.projectId}</Text>
            </div>
          </div>
          <div className={styles.detailLine}>
            <div className={styles.label}>
              <Text>Dataset:</Text>
            </div>
            <div className={styles.value}>
              <Text>{row.tableReference.datasetId}</Text>
            </div>
          </div>
          <div className={styles.detailLine}>
            <div className={styles.label}>
              <Text>Location:</Text>
            </div>
            <div className={styles.value}>
              <Text>{row.location}</Text>
            </div>
          </div>
          <div className={styles.detailLine}>
            <div className={styles.label}>
              <Text>Column quantity:</Text>
            </div>
            <div className={styles.value}>
              <Text>{row.schema.fields.length}</Text>
            </div>
          </div>
        </div>
        <div className={styles.typeIcon}>
          {row.type === "TABLE" && <Database />}
          {row.type === "VIEW" && <TableView />}
        </div>
      </div>
      <div className={styles.itemFooter}>
        <div className={styles.time}>
          <AccessTimeIcon className={styles.timeIcon} />
          <div className={styles.value}>
            {covertTime(Number(row.creationTime))}
          </div>
        </div>
        <div className={styles.operateIcon}>
          <VisibilityIcon
            size="small"
            onClick={() => {
              showBackItem(row);
            }}
          />
          <Delete
            onClick={() => {
              removeCartItem(seq);
            }}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};

export default CartItem;
